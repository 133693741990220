import React from "react";
import {
  UseFormRegister,
  UseFieldArrayReturn,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormGetValues,
  UseFormWatch,
} from "react-hook-form";
import { FDSearchStateContext } from "../../containers/FDSearchState";
import { SearchResults } from "../../utils/fooddata-central.types";
import { Button, CloseButton, InlineLinkButton } from "../primitives/Button";
import { Input, Select } from "../primitives/Inputs";
import { Spinner } from "../primitives/Spinner";
import { TransitionView } from "../primitives/TransitionView";
import { ToggleView } from "../primitives/ToggleView";
import { IRecipe } from "./Recipe";
import { roundDecimals } from "../../utils/utils";
import { parseMacroServingSize, ServingUnit } from "../../utils/parse-macros";
import "./IngredientInput.css";

interface NutritionFacts {
  /**
   * Grams of protein
   */
  protein: number;
  /**
   * Grams of fat
   */
  fat: number;
  /**
   * Grams of carbs
   */
  carbs: number;
}

export interface Ingredient {
  id: string;
  /**
   *  FoodData Central ID (optional, since manually entering ingredients is also allowed)
   */
  fdcId?: number;
  /**
   * Ingredient name (default value)
   */
  name: string;
  /**
   * Ingredient nutrient facts (default value)
   */
  nutritionFacts: NutritionFacts;
  /**
   * Serving size (default value)
   */
  servingSize: number;
  /**
   * Unit of serving size (default value)
   */
  servingUnit: ServingUnit;
  /**
   * Should the nutritionFacts auto-scale as servingSize is changed
   */
  autoScale: boolean;
}

export interface IngredientInputProps {
  /**
   * React-hook-form connector
   */
  register: UseFormRegister<IRecipe>;
  remove: UseFieldArrayReturn<IRecipe>["remove"];
  setFocus: UseFormSetFocus<IRecipe>;
  setValue: UseFormSetValue<IRecipe>;
  getValues: UseFormGetValues<IRecipe>;
  watch: UseFormWatch<IRecipe>;
  /**
   * React-hook-form useFieldArray index
   */
  fieldIndex: number;
  ingredient: Ingredient;
}

/**
 * Inputs for a recipe ingredient
 */
export const IngredientInput: React.FC<IngredientInputProps> = ({
  register,
  remove,
  setFocus,
  setValue,
  getValues,
  watch,
  fieldIndex = 0,
}) => {
  React.useEffect(() => {
    setFocus(`ingredients.${fieldIndex}.name`);
  }, [setFocus, fieldIndex]);
  const searchTerm = watch(`ingredients.${fieldIndex}.name`);
  const servingSize = watch(`ingredients.${fieldIndex}.servingSize`);
  const shouldAutoScale = watch(`ingredients.${fieldIndex}.autoScale`);
  const servingUnit = watch(`ingredients.${fieldIndex}.servingUnit`);

  const { search, abort, searchResults, isSearching, clearResults } =
    React.useContext(FDSearchStateContext);
  const resetAutoScale = useAutoscaling({
    shouldAutoScale,
    servingSize,
    servingUnit,
    getValues,
    setValue,
    fieldIndex,
  });
  const parseAndPopulate = useSearchResultParser({
    fieldIndex,
    setValue,
    resetAutoScale,
    clearResults,
  });
  const { searchState, showSearch, hideSearchDelay } =
    useAutoHideSearchButton();

  const handleEnter: React.KeyboardEventHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      search(searchTerm);
    }
  };

  const searchCx = ["ingredientInput--search"];
  if (searchState.delayedHide) {
    searchCx.push("ingredientInput__hidden");
  }

  return (
    <div>
      <input
        type="hidden"
        {...register(`ingredients.${fieldIndex}.id` as const)}
      />
      <input
        type="hidden"
        {...register(`ingredients.${fieldIndex}.fdcId` as const, {
          valueAsNumber: true,
        })}
      />
      <div className="ingredientInput--name">
        <label aria-label="Ingredient Name" onKeyDown={handleEnter}>
          Name
          <br />
          <Input
            type="text"
            required
            onFocus={showSearch}
            {...register(`ingredients.${fieldIndex}.name` as const, {
              onBlur: hideSearchDelay,
            })}
          />
        </label>
        <div>
          {searchState.visible && (
            <Button
              label={
                <div className="ingredientInput--search-label">
                  <SearchIcon />
                  <span>Search</span>
                </div>
              }
              size="small"
              onClick={() => {
                search(searchTerm);
                hideSearchDelay();
              }}
              onFocus={showSearch}
              onBlur={hideSearchDelay}
              className={searchCx.concat("ml-1").join(" ")}
              state={isSearching ? "active" : undefined}
              disabled={isSearching}
            />
          )}
        </div>
        <div style={{ flexGrow: "1" }} />
        {isSearching && <CancelSearchAction cancelSearch={abort} />}
        <div>
          <ToggleView
            a={
              <CloseButton
                aria-label="Remove ingredient"
                onClick={() => {
                  abort();
                  remove(fieldIndex);
                }}
              />
            }
            b={<Spinner active={isSearching} className="ml-2" />}
            toggle={isSearching}
          />
        </div>
      </div>

      {searchResults.length > 0 && (
        <SearchResultList
          populateFromSearchResult={parseAndPopulate}
          isSearching={isSearching}
          searchResults={searchResults}
          clearResults={clearResults}
        />
      )}

      <div className="ingredientInput--facts mt-2">
        <div>
          Nutrition Facts
          <div className="ingredientInput--factInputs">
            <label>
              <Input
                type="number"
                min="0"
                step=".01"
                defaultValue={0}
                disabled={shouldAutoScale}
                {...register(
                  `ingredients.${fieldIndex}.nutritionFacts.protein` as const,
                  { valueAsNumber: true }
                )}
              />
              g protein
            </label>
            <br />
            <label>
              <Input
                type="number"
                min="0"
                step=".01"
                defaultValue={0}
                disabled={shouldAutoScale}
                {...register(
                  `ingredients.${fieldIndex}.nutritionFacts.fat` as const,
                  { valueAsNumber: true }
                )}
              />
              g fat
            </label>
            <br />
            <label>
              <Input
                type="number"
                min="0"
                step=".01"
                defaultValue={0}
                disabled={shouldAutoScale}
                {...register(
                  `ingredients.${fieldIndex}.nutritionFacts.carbs` as const,
                  { valueAsNumber: true }
                )}
              />
              g carbs
            </label>
          </div>
          <label>
            <Input
              type="checkbox"
              {...register(`ingredients.${fieldIndex}.autoScale` as const)}
            />
            auto-scale
          </label>
        </div>

        <div className="ingredientInput--sizeUnit">
          <div>
            <label>
              Serving Size
              <br />
              <Input
                type="number"
                min="0"
                step=".01"
                required
                {...register(`ingredients.${fieldIndex}.servingSize`, {
                  valueAsNumber: true,
                })}
              />
            </label>
          </div>

          <div>
            <label>
              Serving Size Unit
              <br />
              <Select {...register(`ingredients.${fieldIndex}.servingUnit`)}>
                <option>{ServingUnit.Grams}</option>
                <option>{ServingUnit.Milliliters}</option>
              </Select>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

function useAutoscaling({
  shouldAutoScale,
  servingSize,
  servingUnit,
  getValues,
  setValue,
  fieldIndex,
}: {
  shouldAutoScale: boolean;
  servingSize: number;
  servingUnit: ServingUnit;
  getValues: UseFormGetValues<IRecipe>;
  setValue: UseFormSetValue<IRecipe>;
  fieldIndex: number;
}) {
  const [autoScaleState, setAutoScaleState] = React.useState<{
    nutritionFacts?: NutritionFacts;
    servingSize: number;
  }>({
    servingSize: 0,
  });
  React.useEffect(() => {
    if (shouldAutoScale) {
      if (!autoScaleState.nutritionFacts) {
        setAutoScaleState({
          nutritionFacts: {
            protein: getValues(
              `ingredients.${fieldIndex}.nutritionFacts.protein`
            ),
            fat: getValues(`ingredients.${fieldIndex}.nutritionFacts.fat`),
            carbs: getValues(`ingredients.${fieldIndex}.nutritionFacts.carbs`),
          },
          servingSize: getValues(`ingredients.${fieldIndex}.servingSize`),
        });
      } else if (servingSize) {
        const scaleFactor = servingSize / autoScaleState.servingSize;
        setValue(
          `ingredients.${fieldIndex}.nutritionFacts.protein`,
          roundDecimals(scaleFactor * autoScaleState.nutritionFacts.protein)
        );
        setValue(
          `ingredients.${fieldIndex}.nutritionFacts.fat`,
          roundDecimals(scaleFactor * autoScaleState.nutritionFacts.fat)
        );
        setValue(
          `ingredients.${fieldIndex}.nutritionFacts.carbs`,
          roundDecimals(scaleFactor * autoScaleState.nutritionFacts.carbs)
        );
      }
    } else if (autoScaleState.servingSize !== 0) {
      // Reset state so that any manual changes to nutritionFacts will be picked up,
      // if autoScale is re-enabled
      setAutoScaleState({ servingSize: 0 });
    }
  }, [
    shouldAutoScale,
    servingSize,
    getValues,
    setValue,
    fieldIndex,
    autoScaleState,
  ]);
  return () => setAutoScaleState({ servingSize: 0 });
}

function useSearchResultParser({
  fieldIndex,
  setValue,
  resetAutoScale,
  clearResults,
}: {
  resetAutoScale: () => void;
  setValue: UseFormSetValue<IRecipe>;
  fieldIndex: number;
  clearResults: () => void;
}) {
  return function parseAndPopulate(food: SearchResults["foods"][0]) {
    const macros = parseMacroServingSize(food);
    setValue(`ingredients.${fieldIndex}.fdcId`, food.fdcId);
    setValue(`ingredients.${fieldIndex}.name`, food.description);
    setValue(`ingredients.${fieldIndex}.servingSize`, macros.servingSize);
    setValue(`ingredients.${fieldIndex}.servingUnit`, macros.servingUnit);
    setValue(
      `ingredients.${fieldIndex}.nutritionFacts.protein`,
      macros.protein
    );
    setValue(`ingredients.${fieldIndex}.nutritionFacts.fat`, macros.fat);
    setValue(`ingredients.${fieldIndex}.nutritionFacts.carbs`, macros.carbs);
    resetAutoScale();
    setValue(`ingredients.${fieldIndex}.autoScale`, true);
    clearResults();
  };
}

function useAutoHideSearchButton() {
  const [searchVisibleState, _setVisibleState] = React.useState<{
    visible: boolean;
    timerId: number;
    delayedHide: boolean;
  }>({
    visible: false,
    timerId: 0,
    delayedHide: false,
  });
  const showSearch = () => {
    clearTimeout(searchVisibleState.timerId);
    _setVisibleState({ visible: true, timerId: 0, delayedHide: false });
  };
  const hideSearchDelay = () => {
    _setVisibleState((prev) => ({ ...prev, delayedHide: true }));
  };
  React.useEffect(() => {
    if (searchVisibleState.delayedHide && !searchVisibleState.timerId) {
      const timerId = window.setTimeout(() => {
        _setVisibleState({ visible: false, timerId: 0, delayedHide: false });
      }, 300);
      _setVisibleState((prev) => ({ ...prev, timerId }));
    }
    return () => {
      clearTimeout(searchVisibleState.timerId);
    };
  }, [searchVisibleState.delayedHide, searchVisibleState.timerId]);
  return { searchState: searchVisibleState, showSearch, hideSearchDelay };
}

const SearchResultList: React.FC<{
  searchResults: SearchResults["foods"];
  isSearching: boolean;
  populateFromSearchResult: any;
  clearResults?: React.MouseEventHandler;
}> = ({
  searchResults,
  isSearching,
  populateFromSearchResult,
  clearResults,
}) => {
  const cx = ["SearchResultList"];
  if (isSearching) cx.push("SearchResultList__searching");
  return (
    <>
      <ul aria-label="Search results" className={cx.join(" ")}>
        {searchResults.map((food) => (
          <SearchResultItem
            food={food}
            key={food.fdcId}
            onClick={() => !isSearching && populateFromSearchResult(food)}
          />
        ))}
      </ul>
      <div className="float-r">
        <Button
          size="tiny"
          square
          className="mb-2"
          onClick={clearResults}
          label="Clear results"
        />
      </div>
      <div className="clear" />
    </>
  );
};

const truncate = (str = "", maxLength = 140) =>
  str.length < maxLength ? str : str.slice(0, maxLength) + "...";

const SearchResultItem: React.FC<{
  food: SearchResults["foods"][0];
  onClick: React.MouseEventHandler;
}> = ({ food, ...props }) => {
  let descriptionSuffix: any;
  let ingredients: any;
  if (food.brandName) {
    descriptionSuffix = ` (${food.brandName} brand)`;
  }
  if (food.ingredients) {
    ingredients = (
      <small className="SearchResultItem--ingredients">
        {truncate(food.ingredients)}
      </small>
    );
  }
  return (
    <li key={food.fdcId} {...props} className="SearchResultItem mt-2 mb-1">
      <InlineLinkButton className="SearchResultItem--title">
        {food.description} {descriptionSuffix}
      </InlineLinkButton>
      {ingredients}
    </li>
  );
};

const CancelSearchAction: React.FC<{
  cancelSearch?: React.MouseEventHandler;
}> = ({ cancelSearch }) => {
  return (
    <TransitionView name="CancelSearchAction">
      <Button
        size="small"
        label="Cancel?"
        className="ml-2"
        onClick={cancelSearch}
      />
    </TransitionView>
  );
};

const SearchIcon: React.FC = () => (
  <svg
    viewBox="0 0 500 500"
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      stroke="currentColor"
      strokeWidth="60px"
      fill="none"
      cx="218.635"
      cy="194.528"
      rx="156.546"
      ry="154.288"
    />
    <path
      stroke="none"
      fill="currentColor"
      d="M 284.88 334.353 L 387.634 480.989 L 446.832 437.712 L 334.316 296.873 L 284.88 334.353 Z"
    />
  </svg>
);
