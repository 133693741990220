import React from "react";
import { Button } from "./Button";
import "./MenuButton.css";

interface MenuButtonProps {
  label: string;
  style?: object;
  optionWidth?: number;
  className?: string;
}

const MenuContext = React.createContext<{
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  setIsOpen: () => {},
});

export const MenuButton: React.FC<MenuButtonProps> = ({
  label,
  style,
  children,
  optionWidth,
  className,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const childrenRefs = React.useRef<HTMLButtonElement[]>([]);

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    const isFirstChild = document.activeElement === childrenRefs.current[0];
    const isLastChild =
      document.activeElement ===
      childrenRefs.current[childrenRefs.current.length - 1];

    switch (event.key) {
      case "Escape":
        event.preventDefault();
        setIsOpen(false);
        return;

      case "ArrowDown":
      case "ArrowUp":
        event.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
          return;
        }
        for (
          let childIndex = 0;
          childIndex < childrenRefs.current.length;
          childIndex++
        ) {
          if (childrenRefs.current[childIndex] === document.activeElement) {
            if (
              event.key === "ArrowDown" &&
              childIndex < childrenRefs.current.length - 1
            ) {
              childrenRefs.current[childIndex + 1].focus();
            }
            if (event.key === "ArrowUp" && childIndex > 0) {
              childrenRefs.current[childIndex - 1].focus();
            }
            return;
          }
        }
        // No child has focus
        childrenRefs.current[0]?.focus();
        return;

      case "Tab":
        if (
          (isFirstChild && event.shiftKey) ||
          (isLastChild && !event.shiftKey)
        ) {
          // Close menu, if tabbing away
          setIsOpen(false);
        }
        return;
    }
  };

  return (
    <MenuContext.Provider value={{ setIsOpen }}>
      {isOpen && (
        <div
          className="MenuButton--backdrop"
          onClick={() => setIsOpen(false)}
        />
      )}
      <div
        className={["MenuButton--container", className]
          .filter(Boolean)
          .join(" ")}
        style={style}
        onKeyDown={handleKeyDown}
      >
        <Button
          square
          label={
            <>
              <Chevron color={"rgba(0,0,0,.7)"} active={isOpen} />
              {label}
            </>
          }
          state={isOpen ? "active" : undefined}
          onClick={() => setIsOpen((_o) => !_o)}
          className="MenuButton--button"
        />
        {isOpen && (
          <ul
            className="MenuButton--options"
            role="menu"
            style={{ width: optionWidth }}
          >
            {React.Children.map(children, (child, index) => {
              if (index === 0) childrenRefs.current = [];
              return isMenuButton(child)
                ? React.cloneElement(child as React.ReactElement, {
                    ref: (el: HTMLButtonElement) =>
                      (childrenRefs.current[childrenRefs.current.length] = el),
                  })
                : child;
            })}
          </ul>
        )}
      </div>
    </MenuContext.Provider>
  );
};

function isMenuButton(child: any): boolean {
  try {
    return child.type === MenuButtonOption;
  } catch (e) {
    return false;
  }
}

interface MenuButtonOptionProps {
  label: string;
  onClick: () => void;
}

export const MenuButtonOption = React.forwardRef<
  HTMLButtonElement,
  MenuButtonOptionProps
>(({ label, onClick }, ref) => {
  const { setIsOpen } = React.useContext(MenuContext);
  const [clickFeedbackState, setClickFeedbackState] = React.useState(false);

  React.useEffect(() => {
    if (clickFeedbackState) {
      const timerId = setTimeout(() => {
        setClickFeedbackState(false);
        setIsOpen(false);
      }, 300);
      return () => clearTimeout(timerId);
    }
  }, [clickFeedbackState, setIsOpen]);

  const _click = () => {
    setClickFeedbackState(true);
    onClick();
  };

  const cx = ["MenuButtonOption"];
  if (clickFeedbackState) {
    cx.push("MenuButtonOption__clickfeedback");
  }

  return (
    <li className={cx.join(" ")} onClick={_click}>
      <button ref={ref}>{label}</button>
    </li>
  );
});

export const MenuButtonOptionSeparator: React.FC = () => {
  return <div className="MenuButtonOptionSeparator" />;
};

const Chevron: React.FC<{
  color?: string;
  active?: boolean;
}> = ({ color = "currentColor", active = false }) => {
  const cx = ["Chevron"];
  if (active) {
    cx.push("Chevron__active");
  }
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx.join(" ")}
    >
      <path
        d="M4 3L9 7L4 11"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
