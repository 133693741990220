import React from "react";
import { useNavigate, useLocation, matchRoutes } from "react-router-dom";
import "./MainPage.css";
import { ListManager } from "../components/primitives/ListManager";
import { DetailView } from "../components/primitives/DetailView";
import { AppDescriptionView } from "../components/views/AppDescriptionView";
import { Recipe } from "../components/views/Recipe";
import { Profile } from "../components/views/Profile";
import { Order } from "../components/views/Order";
import { Batch } from "../components/views/Batch";
import { Footer } from "../components/views/Footer";
import {
  RecipeContext,
  ProfileContext,
  OrderContext,
  BatchContext,
} from "../containers/DataProvider";

const RecipeNewRoute = { path: "/recipe" };
const RecipeEditRoute = { path: "/recipe/:id" };
const ProfileNewRoute = { path: "/profile" };
const ProfileEditRoute = { path: "/profile/:id" };
const OrderNewRoute = { path: "/order" };
const OrderEditRoute = { path: "/order/:id" };
const BatchNewRoute = { path: "/batch" };
const BatchEditRoute = { path: "/batch/:id" };
const AboutRoute = { path: "/about" };
const WelcomeRoute = { path: "/welcome" };

interface MainPageProps {
  header?: React.ReactNode;
}

export const MainPage: React.FC<MainPageProps> = ({ header = null }) => {
  const { recipes, upsertRecipe } = React.useContext(RecipeContext);
  const { profiles, upsertProfile } = React.useContext(ProfileContext);
  const { orders, upsertOrder } = React.useContext(OrderContext);
  const { batches, upsertBatch } = React.useContext(BatchContext);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (!recipes.length && !profiles.length) {
      navigate("/welcome");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Using matchRoutes directly to achieve optional parameters (not possible with Route)
   * and to access route parameters (useParameters only works from child components).
   */
  const match = matchRoutes(
    [
      RecipeNewRoute,
      RecipeEditRoute,
      ProfileNewRoute,
      ProfileEditRoute,
      OrderNewRoute,
      OrderEditRoute,
      BatchNewRoute,
      BatchEditRoute,
      AboutRoute,
      WelcomeRoute,
    ],
    location
  );

  const matchedRoute = match && match[0].route;
  const matchedRouteId = match && match[0].params.id;

  return (
    <div>
      {[RecipeNewRoute, RecipeEditRoute].includes(matchedRoute as any) && (
        <DetailView>
          <Recipe
            recipe={recipes.find((_m) => _m.id === matchedRouteId)}
            onCancel={() => navigate("/")}
            onUpsert={(data) => {
              upsertRecipe(data);
              navigate("/");
            }}
          />
        </DetailView>
      )}
      {[ProfileNewRoute, ProfileEditRoute].includes(matchedRoute as any) && (
        <DetailView>
          <Profile
            profile={profiles.find((_p) => _p.id === matchedRouteId)}
            onCancel={() => navigate("/")}
            onUpsert={(data) => {
              upsertProfile(data);
              navigate(-1);
            }}
          />
        </DetailView>
      )}
      {[OrderNewRoute, OrderEditRoute].includes(matchedRoute as any) && (
        <DetailView>
          <Order
            order={orders.find((_o) => _o.id === matchedRouteId)}
            recipes={recipes}
            profiles={profiles}
            onCancel={() => navigate("/")}
            onUpsert={(data) => {
              upsertOrder(data);
              navigate("/");
            }}
          />
        </DetailView>
      )}
      {[BatchNewRoute, BatchEditRoute].includes(matchedRoute as any) && (
        <DetailView>
          <Batch
            batch={batches.find((_o) => _o.id === matchedRouteId)}
            batches={batches}
            recipes={recipes}
            orders={orders}
            profiles={profiles}
            onCancel={() => navigate("/")}
            onUpsertContinue={(data) => {
              upsertBatch(data);
              navigate(`/batch/${data.id}`, { replace: true });
            }}
            onUpsert={(data) => {
              upsertBatch(data);
              navigate("/");
            }}
          />
        </DetailView>
      )}
      {[AboutRoute, WelcomeRoute].includes(matchedRoute as any) && (
        <DetailView>
          <AppDescriptionView
            dismiss={() => navigate("/")}
            welcome={WelcomeRoute === matchedRoute}
          />
        </DetailView>
      )}

      <article className="page--contents">
        {header}
        <section>
          <h2>Recipes</h2>
          <p>Create recipes and look up nutrition facts</p>
          <ListManager
            label="recipes"
            onClickAddItem={() => navigate("/recipe")}
            onClickSelectItem={(itemId) => navigate(`/recipe/${itemId}`)}
            items={recipes}
          />
        </section>

        <section>
          <h2>Profiles</h2>
          <p>Use multiple profiles to manage unique nutritional goals</p>
          <ListManager
            label="profiles"
            onClickAddItem={() => navigate("/profile")}
            onClickSelectItem={(itemId) => navigate(`/profile/${itemId}`)}
            items={profiles}
          />
        </section>

        <section>
          <h2>Orders</h2>
          <p>Customize recipes to satisfy individual nutritional needs</p>
          <ListManager
            label="orders"
            onClickAddItem={
              recipes.length > 0 && profiles.length > 0
                ? () => navigate("/order")
                : undefined
            }
            onClickSelectItem={(itemId) => navigate(`/order/${itemId}`)}
            items={orders}
          />
        </section>

        <section>
          <h2>Batches</h2>
          <p>Generate grocery lists and prep notes</p>
          <ListManager
            label="batches"
            onClickAddItem={
              orders.length > 0 ? () => navigate("/batch") : undefined
            }
            onClickSelectItem={(itemId) => navigate(`/batch/${itemId}`)}
            items={batches}
          />
        </section>
        <Footer />
      </article>
    </div>
  );
};
