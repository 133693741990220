import React from "react";
import { useNavigate } from "react-router-dom";
import { FileContext } from "../../containers/DataProvider";
import {
  MenuButton,
  MenuButtonOption,
  MenuButtonOptionSeparator,
} from "../primitives/MenuButton";
import "./FileHeader.css";

export const FileHeader: React.FC = (props) => {
  const { exportSession, importSession } = React.useContext(FileContext);
  const navigate = useNavigate();

  const confirmImport = () => {
    window.confirm(
      "This will overwrite your current session. Press OK to continue."
    ) && importSession();
  };

  return (
    <>
      <header className="FileHeader--container">
        <h1>Macrows</h1>
        <MenuButton label="Menu" className="FileHeader--menu" optionWidth={140}>
          <MenuButtonOption label="About" onClick={() => navigate("/about")} />
          <MenuButtonOptionSeparator />
          <MenuButtonOption label="Import..." onClick={confirmImport} />
          <MenuButtonOption label="Export Backup" onClick={exportSession} />
        </MenuButton>
      </header>
    </>
  );
};
