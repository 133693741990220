export function roundDecimals(num = 0, decimalPlaces = 2) {
  return Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces;
}

export function gramsToCalories(macros: {
  protein: number;
  fat: number;
  carbs: number;
}) {
  return {
    protein: macros.protein * 4,
    fat: macros.fat * 9,
    carbs: macros.carbs * 4,
  };
}
