import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm, UseFormRegister } from "react-hook-form";
import { IRecipe } from "./Recipe";
import { IProfile } from "./Profile";
import { IOrder } from "./Order";
import { Heading } from "../primitives/Heading";
import { Button } from "../primitives/Button";
import { Input } from "../primitives/Inputs";
import { ServingUnit } from "../../utils/parse-macros";
import { roundDecimals } from "../../utils/utils";
import "./Batch.css";

export interface BatchProps {
  batch?: IBatch;
  batches: IBatch[];
  recipes: IRecipe[];
  orders: IOrder[];
  profiles: IProfile[];
  onCancel: () => void;
  onUpsertContinue: (batch: IBatch) => void;
  onUpsert: (batch: IBatch) => void;
}

export interface IBatch {
  id: string;
  created: string;
  name: string;
  orderIds: string[];
  completedGrocies: string[];
}

const validateOrderIds = (v: string[]) => {
  return v.length > 0;
};

const OrderSelection: React.FC<{
  orders: IOrder[];
  register: UseFormRegister<IBatch>;
  error?: boolean;
}> = ({ orders = [], register, error }) => (
  <div>
    {error && (
      <p style={{ color: "red" }}>Batch must contain at least one order</p>
    )}
    {orders.map((_o) => (
      <label key={_o.id}>
        <Input
          type="checkbox"
          value={_o.id}
          {...register("orderIds", { validate: validateOrderIds })}
        />
        {_o.name}
        <br />
      </label>
    ))}
    {/* https://github.com/react-hook-form/react-hook-form/issues/7500 */}
    {orders.length === 1 && (
      <Input
        type="checkbox"
        style={{ display: "none" }}
        value="--react-hook-form-workaround--"
        {...register("orderIds")}
      />
    )}
    {orders.length === 0 && (
      <>
        <input
          type="hidden"
          {...register("orderIds", { validate: validateOrderIds })}
        />
        <p>There are no unprocessed orders.</p>
      </>
    )}
  </div>
);

interface GroceryMap {
  [ingredientId: string]: {
    name: string;
    servings: number;
    servingSize: number;
    servingUnit: ServingUnit;
  };
}

const GroceryList: React.FC<{
  includedOrders: IOrder[];
  recipes: IRecipe[];
  register: UseFormRegister<IBatch>;
}> = ({ includedOrders, recipes, register }) => {
  const groceryMap = includedOrders.reduce<GroceryMap>(
    (orderPrev, orderCurr) => {
      return orderCurr.meals.reduce<GroceryMap>((mealPrev, mealCurr) => {
        const recipe = recipes.find((_m) => _m.id === mealCurr.recipeId);
        if (!recipe) {
          console.error(
            `Missing Recipe ${mealCurr.recipeId} associated with CustomizedMeal ${mealCurr.id}`
          );
          return mealPrev;
        }
        return recipe.ingredients.reduce<GroceryMap>(
          (ingredientPrev, ingredientCurr) => {
            const customized = mealCurr.ingredients.find(
              (_i) => _i.id === ingredientCurr.id
            );
            const cServings = customized
              ? customized.amount / ingredientCurr.servingSize
              : 1;
            return {
              ...ingredientPrev,
              [ingredientCurr.id]: {
                servings:
                  (ingredientPrev[ingredientCurr.id]?.servings || 0) +
                  cServings * mealCurr.quantity,
                name: ingredientCurr.name,
                servingSize: ingredientCurr.servingSize,
                servingUnit: ingredientCurr.servingUnit,
              },
            };
          },
          mealPrev
        );
      }, orderPrev);
    },
    {}
  );

  return (
    <div>
      <Heading>Grocery List</Heading>
      <ul className="Batch--grocery-list">
        {Object.keys(groceryMap).map((_k) => (
          <li key={_k}>
            <label>
              <Input
                type="checkbox"
                value={_k}
                {...register("completedGrocies")}
              />
              {groceryMap[_k].name} - {calcTotal(groceryMap[_k])}
              {` `}({roundDecimals(groceryMap[_k].servings, 1)} servings of{" "}
              {groceryMap[_k].servingSize} {groceryMap[_k].servingUnit})
            </label>
          </li>
        ))}
        {Object.keys(groceryMap).length === 1 && (
          <Input
            type="checkbox"
            style={{ display: "none" }}
            value="--react-hook-form-workaround--"
            {...register("completedGrocies")}
          />
        )}
      </ul>
    </div>
  );
};

const calcTotal = (map: GroceryMap[string]) => {
  let { servingUnit } = map;
  let total = map.servings * map.servingSize;

  switch (map.servingUnit) {
    case ServingUnit.Grams:
      if (total > 1000) {
        total /= 1000;
        servingUnit = ServingUnit.Killigrams;
      }
      break;

    case ServingUnit.Milliliters:
      if (total > 1000) {
        total /= 1000;
        servingUnit = ServingUnit.Liters;
      }
      break;
  }

  return `${roundDecimals(total, 1)} ${servingUnit}`;
};

const PrepSheet: React.FC<{
  includedOrders: IOrder[];
  recipes: IRecipe[];
  profiles: IProfile[];
}> = ({ includedOrders, recipes, profiles }) => {
  return (
    <div>
      <Heading>Prep Notes</Heading>
      {includedOrders.map((_o) => (
        <div key={_o.id}>
          {_o.meals.map((_customized) => {
            const recipe = recipes.find(
              (_mm) => _mm.id === _customized.recipeId
            );
            return (
              <div key={_customized.id}>
                <p>
                  {profiles.find((_p) => _p.id === _customized.profileId)?.name}
                  's {recipe?.name} (COUNT = {_customized.quantity})
                </p>
                <ul>
                  {recipe?.ingredients.map((_i) => {
                    const cIngr = _customized.ingredients.find(
                      (_ci) => _ci.id === _i.id
                    );
                    return (
                      <li key={_i.id}>
                        {roundDecimals(
                          cIngr ? cIngr.amount : _i.servingSize,
                          1
                        )}{" "}
                        {_i.servingUnit} of {_i.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export const Batch: React.FC<BatchProps> = ({
  batch = {
    id: "NEW",
    created: `${Math.round(Date.now() / 1000)}`,
    name: "",
    orderIds: [],
    completedGrocies: [],
  },
  batches = [],
  orders = [],
  recipes = [],
  profiles = [],
  onCancel,
  onUpsertContinue,
  onUpsert,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm<IBatch>({
    defaultValues: {
      ...batch,
      id: batch.id === "NEW" ? uuidv4() : batch.id,
    },
  });
  const [selectingOrders, setSelectingOrders] = React.useState<boolean>(
    !batch.orderIds?.length
  );

  const includedOrders = orders.filter((_o) => batch.orderIds?.includes(_o.id));
  const availableOrders = batches
    .reduce((prev, curr) => {
      return prev.filter((_o) => !curr.orderIds.includes(_o.id));
    }, orders)
    .filter((_o) => !batch.orderIds?.includes(_o.id))
    .concat(includedOrders);

  const saveAndProcess = (_b: IBatch) => {
    onUpsertContinue(_b);
    setSelectingOrders(false);
    reset(_b);
  };

  const discardEdits = () => {
    setSelectingOrders(false);
    reset(batch);
  };

  return (
    <form onSubmit={handleSubmit(saveAndProcess)}>
      {selectingOrders && (
        <>
          <h1>{batch.id === "NEW" ? "Create" : "Edit"} Batch</h1>
          {availableOrders.length > 0 && (
            <label>
              <Heading>Batch Name</Heading>
              <Input required {...register("name")} aria-label="Batch name" />
            </label>
          )}
          <Heading>Include Orders</Heading>
          <OrderSelection
            orders={availableOrders}
            register={register}
            error={!!errors.orderIds}
          />
        </>
      )}
      {!selectingOrders && (
        <>
          <h1>Process Batch</h1>
          <Heading>Included Orders</Heading>
          <ul>
            {batch.orderIds?.map((_id) => (
              <li key={_id}>{orders.find((_o) => _o.id === _id)?.name}</li>
            ))}
          </ul>
          <GroceryList
            includedOrders={includedOrders}
            recipes={recipes}
            register={register}
          />
          <PrepSheet
            includedOrders={includedOrders}
            recipes={recipes}
            profiles={profiles}
          />
        </>
      )}
      <div style={{ display: "inline", float: "right" }}>
        {selectingOrders ? (
          <>
            <Button
              size="small"
              onClick={() => (batch.id === "NEW" ? onCancel() : discardEdits())}
              label="Cancel"
            />
            {isDirty && availableOrders.length > 0 && (
              <Button
                primary
                size="small"
                type="submit"
                label="Save and Process"
              />
            )}
          </>
        ) : (
          <>
            <Button size="small" onClick={onCancel} label="Close" />
            {isDirty ? (
              <Button
                primary
                size="small"
                onClick={handleSubmit((_o) => onUpsert(_o))}
                label="Save"
              />
            ) : (
              <Button
                size="small"
                onClick={() => setSelectingOrders(true)}
                label="Edit Batch"
              />
            )}
          </>
        )}
      </div>
      <div style={{ clear: "both" }} />
    </form>
  );
};
