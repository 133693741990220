type AbortCallback = () => void;

export interface AbortableRequest<T> {
  abort: AbortCallback;
  ready: Promise<T>;
}

export function abortableFetch(request: RequestInfo, opts?: RequestInit) {
  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    ready: fetch(request, { ...opts, signal }),
  };
}
