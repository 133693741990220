import React from "react";
import { Button } from "../primitives/Button";
import { Heading } from "../primitives/Heading";

export const AppDescriptionView: React.FC<{
  dismiss: () => void;
  welcome?: boolean;
}> = ({ dismiss, welcome }) => (
  <div>
    <h1>
      {welcome && "Welcome to "}Macrows{welcome && "!"}
    </h1>
    <p>
      Macrows is a macro-aware portion-calculator and meal prepping assistant.
      Use it to customize recipes and help with meal planning and preparation.
    </p>
    <Heading>Local database only</Heading>
    <p>
      Data is only stored in your browser's <code>localStorage</code> database.
      There is no back-end server or remote database.
    </p>
    <p>
      Because <code>localStorage</code> <strong>may be erased</strong> (for
      example, if you clear your browser's history), there is also an export
      option to backup your Macrows data. Macrows can also import a previously
      exported database.
    </p>
    <Heading>Public Beta</Heading>
    <p>
      Macrows is still being developed, and breaking changes to the feature-set
      and database structure <em>may</em> occur.
    </p>
    <Button
      className="float-r"
      label={welcome ? "Continue to App" : "Return to App"}
      onClick={dismiss}
      primary={welcome}
    />
    <div className="clear" />
  </div>
);
