import React from "react";
import "./Spinner.css";

const stop1 = "rgba(40, 140, 255, 1)";
const stop2 = "rgba(200, 255, 255, 1)";

export const Spinner: React.FC<{
  active: boolean;
  className?: string;
}> = ({ active = false, className }) => {
  const cx = ["Spinner", className, active && "Spinner__active"];
  return (
    <div className={cx.filter(Boolean).join(" ")}>
      <div className="Spinner--spinner">
        <svg
          className="Spinner--img"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          viewBox="0 0 463 462"
          xmlSpace="preserve"
        >
          <g transform="matrix(0.59 0 0 0.59 224.25 27.75)">
            <linearGradient
              id="Spinner--gradient"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(1 0 0 1 -40 -40)"
              x1="0"
              y1="40"
              x2="80"
              y2="40"
            >
              <stop offset="0%" style={{ stopColor: stop1 }} />
              <stop offset="100%" style={{ stopColor: stop2 }} />
            </linearGradient>
            <circle
              style={{
                fill: "url(#Spinner--gradient)",
                fillRule: "nonzero",
                opacity: 1,
              }}
              cx="0"
              cy="0"
              r="40"
            />
          </g>
          <g transform="matrix(0.97 0 0 0.97 411.25 258.75)">
            <circle
              style={{
                fill: "url(#Spinner--gradient)",
                fillRule: "nonzero",
                opacity: 1,
              }}
              cx="0"
              cy="0"
              r="40"
            />
          </g>
          <g transform="matrix(1.25 0 0 1.25 273 407.5)">
            <circle
              style={{
                fill: "url(#Spinner--gradient)",
                fillRule: "nonzero",
                opacity: 1,
              }}
              cx="0"
              cy="0"
              r="40"
            />
          </g>
          <g transform="matrix(0.85 0 0 0.85 364.5 99)">
            <circle
              style={{
                fill: "url(#Spinner--gradient)",
                fillRule: "nonzero",
                opacity: 1,
              }}
              cx="0"
              cy="0"
              r="40"
            />
          </g>
          <g transform="matrix(1.41 0 0 1.41 62 346.5)">
            <circle
              style={{
                fill: "url(#Spinner--gradient)",
                fillRule: "nonzero",
                opacity: 1,
              }}
              cx="0"
              cy="0"
              r="40"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};
