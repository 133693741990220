import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm, useFieldArray } from "react-hook-form";
import { Button } from "../primitives/Button";
import { Heading } from "../primitives/Heading";
import { Subitem } from "../primitives/Subitem";
import { Input } from "../primitives/Inputs";
import { IngredientInput, Ingredient } from "./IngredientInput";
import { FDSearchStateProvider } from "../../containers/FDSearchState";

interface RecipeProps {
  recipe?: IRecipe;
  onCancel: () => void;
  onUpsert: (recipe: IRecipe) => void;
}

export type IRecipe = {
  id: string;
  created: string;
  name: string;
  ingredients: Ingredient[];
};

export const Recipe: React.FC<RecipeProps> = ({
  recipe = {
    id: "NEW",
    created: `${Math.round(Date.now() / 1000)}`,
    name: "",
    ingredients: [],
  },
  onCancel,
  onUpsert,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty },
    setFocus,
    getValues,
    setValue,
    watch,
  } = useForm<IRecipe>({
    defaultValues: {
      ...recipe,
      id: recipe.id === "NEW" ? uuidv4() : recipe.id,
    },
  });
  const { fields, append, remove } = useFieldArray<
    IRecipe,
    "ingredients",
    "key"
  >({
    control,
    name: "ingredients",
    keyName: "key",
  });

  const addIngredient = () => {
    append({ id: uuidv4() });
  };

  return (
    <form onSubmit={handleSubmit((_m) => onUpsert(_m))}>
      <h1>{recipe.id === "NEW" ? "Add New " : "Edit "}Recipe</h1>

      <div>
        <label aria-label="Recipe Name">
          <Heading>Name</Heading>
          <Input required {...register("name")} />
        </label>
      </div>

      <Heading>Ingredients</Heading>
      {fields.map((field, index) => (
        <Subitem key={field.key}>
          <FDSearchStateProvider>
            <IngredientInput
              ingredient={field}
              register={register}
              remove={remove}
              fieldIndex={index}
              setFocus={setFocus}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
            />
          </FDSearchStateProvider>
        </Subitem>
      ))}
      {fields.length === 0 && (
        <p style={{ color: "gray" }}>No ingredients have been added yet.</p>
      )}

      <Button onClick={addIngredient} label="Add ingredient" />

      <div style={{ display: "inline", float: "right" }}>
        {isDirty ? (
          <>
            <Button size="small" onClick={onCancel} label="Cancel" />
            <Button primary size="small" type="submit" label="Save" />
          </>
        ) : (
          <Button size="small" onClick={onCancel} label="Close" />
        )}
      </div>
    </form>
  );
};
