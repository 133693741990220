import React from "react";
import {
  UseFormRegister,
  UseFormWatch,
  UseFieldArrayReturn,
} from "react-hook-form";
import { IOrder } from "./Order";
import { IRecipe } from "./Recipe";
import { IProfile } from "./Profile";
import { Ingredient } from "./IngredientInput";
import { SubHeading } from "../primitives/Heading";
import { CircularRange } from "../primitives/CircularRange";
import { CloseButton } from "../primitives/Button";
import { Input } from "../primitives/Inputs";
import { Fraction } from "../primitives/Fraction";
import { roundDecimals, gramsToCalories } from "../../utils/utils";
import "./CustomizedMeal.css";

export interface CustomizedMealInputProps {
  /**
   * React-hook-form connector
   */
  register: UseFormRegister<IOrder>;
  remove: UseFieldArrayReturn<IOrder>["remove"];
  watch: UseFormWatch<IOrder>;
  /**
   * React-hook-form useFieldArray index
   */
  fieldIndex: number;
  profile?: IProfile;
  recipe?: IRecipe;
}

/**
 * Inputs for a recipe ingredient
 */
export const CustomizedMeal: React.FC<CustomizedMealInputProps> = ({
  register,
  watch,
  remove,
  fieldIndex = 0,
  profile,
  recipe,
}) => {
  const hasData = recipe && profile;
  const watchMeal = watch(`meals.${fieldIndex}` as "meals.0");
  const defaultCalories = profile?.nutrientGoals
    ? Object.values(gramsToCalories(profile.nutrientGoals)).reduce(
        (acc, curr) => acc + curr,
        0
      )
    : 1;
  const targetCalRatio = watchMeal.targetCals
    ? watchMeal.targetCals / defaultCalories
    : 1;
  const targetProtein = targetCalRatio * (profile?.nutrientGoals.protein || 1);
  const targetFat = targetCalRatio * (profile?.nutrientGoals.fat || 1);
  const targetCarbs = targetCalRatio * (profile?.nutrientGoals.carbs || 1);

  const sums = (recipe?.ingredients || []).reduce(
    (prev, curr) => {
      const customized = watchMeal?.ingredients.find((_i) => _i.id === curr.id);
      const cServings = customized ? customized.amount / curr.servingSize : 1;
      return {
        fat: prev.fat + cServings * curr.nutritionFacts.fat,
        protein: prev.protein + cServings * curr.nutritionFacts.protein,
        carbs: prev.carbs + cServings * curr.nutritionFacts.carbs,
      };
    },
    { fat: 0, protein: 0, carbs: 0 }
  );

  return (
    <div className="CustomizedMeal">
      {!hasData && (
        <>
          <CloseButton
            aria-label="Remove meal"
            onClick={() => remove(fieldIndex)}
            className="float-r"
          />
          <p className="mb-4">
            <em>Error: missing meal data</em>
          </p>
        </>
      )}
      {hasData && (
        <>
          <CloseButton
            aria-label="Remove meal"
            onClick={() => remove(fieldIndex)}
            className="float-r"
          />
          <SubHeading>
            {profile?.name}'s {recipe?.name}
          </SubHeading>

          <div className="CustomizedMeal--targetCals-row d-flex clear">
            <label className="mt-1 d-block flex-auto pr-2">
              Target Calories:&nbsp;
              <br />
              <Input
                className="w-100"
                type="number"
                min="1"
                defaultValue={roundDecimals(defaultCalories, 0)}
                {...register(`meals.${fieldIndex}.targetCals` as const, {
                  valueAsNumber: true,
                })}
              />
            </label>
            <label className="mt-1 d-block flex-auto pr-2">
              Quantity:&nbsp;
              <br />
              <Input
                className="w-100"
                type="number"
                min="1"
                {...register(`meals.${fieldIndex}.quantity` as const, {
                  valueAsNumber: true,
                })}
              />
            </label>
          </div>

          <table>
            <thead>
              <tr>
                <th></th>
                <th scope="col">Protien</th>
                <th scope="col">Fat</th>
                <th scope="col">Carbs</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Percent of Target</th>
                <td aria-label="percent of target protein">
                  <CircularRange value={(sums.protein / targetProtein) * 100} />
                </td>
                <td aria-label="percent of target fat">
                  <CircularRange value={(sums.fat / targetFat) * 100} />
                </td>
                <td aria-label="percent of target carbs">
                  <CircularRange value={(sums.carbs / targetCarbs) * 100} />
                </td>
              </tr>
              <tr>
                <th scope="row">Fraction of target</th>
                <td aria-label="grams of protein">
                  <Fraction
                    numerator={<>{roundDecimals(sums.protein, 0)}g</>}
                    denominator={<>{roundDecimals(targetProtein, 0)}g</>}
                  />
                </td>
                <td aria-label="grams of fat">
                  <Fraction
                    numerator={<>{roundDecimals(sums.fat, 0)}g</>}
                    denominator={<>{roundDecimals(targetFat, 0)}g</>}
                  />
                </td>
                <td aria-label="grams of carbs">
                  <Fraction
                    numerator={<>{roundDecimals(sums.carbs, 0)}g</>}
                    denominator={<>{roundDecimals(targetCarbs, 0)}g</>}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          {recipe?.ingredients.map((ingredient, ingredientIndex) => (
            <div key={ingredient.id}>
              <Input
                type="hidden"
                value={ingredient.id}
                {...register(
                  `meals.${fieldIndex}.ingredients.${ingredientIndex}.id`
                )}
              />
              <IngredientSummary
                key={ingredient.id}
                ingredient={ingredient}
                {...register(
                  `meals.${fieldIndex}.ingredients.${ingredientIndex}.amount`,
                  { valueAsNumber: true }
                )}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

type IngredientSummaryProps = React.InputHTMLAttributes<HTMLInputElement> & {
  ingredient: Ingredient;
};

const IngredientSummary = React.forwardRef<
  HTMLInputElement,
  IngredientSummaryProps
>(({ ingredient, ...props }, ref) => {
  return (
    <label>
      Ingredient: {ingredient.name}
      <Input
        className="IngredientSummary--slider"
        type="range"
        min="0"
        max={4 * ingredient.servingSize}
        step="0.01"
        defaultValue={ingredient.servingSize}
        {...props}
        ref={ref}
      />
    </label>
  );
});
