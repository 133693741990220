import {
  SearchResults,
  AbbreviatedFoodNutrient,
  Nutrients,
} from "./fooddata-central.types";

export enum ServingUnit {
  Grams = "grams",
  Killigrams = "kg",
  Milliliters = "mL",
  Liters = "liters",
}

/**
 * See https://fdc.nal.usda.gov/data-documentation.html for details
 * about FoodData Central data types
 */
export function parseMacroServingSize(food: SearchResults["foods"][0]) {
  const protein = parseNutrientValueInGrams(
    food.foodNutrients.find((_n) => _n.nutrientId === Nutrients.Protein)
  );
  const fat = parseNutrientValueInGrams(
    food.foodNutrients.find((_n) => _n.nutrientId === Nutrients.Fat)
  );
  const carbs = parseNutrientValueInGrams(
    food.foodNutrients.find((_n) => _n.nutrientId === Nutrients.Carbs)
  );

  // Nutrient data from Foundation, FNDDS, SR Legacy is normalized to 100g portions
  // Nutrient data from Branded dataset is normalized to 100 unit portions, either grams or milliliters
  const servingSize = 100;
  let servingUnit = ServingUnit.Grams;
  switch (food.servingSizeUnit?.toLowerCase()) {
    case "g":
      break;
    case "ml":
      servingUnit = ServingUnit.Milliliters;
      break;
    default:
      console.error("Unfamiliar servingSizeUnit", food);
  }

  return { protein, fat, carbs, servingSize, servingUnit };
}

function parseNutrientValueInGrams(nutritionFact?: AbbreviatedFoodNutrient) {
  if (!nutritionFact) return 0;
  if (nutritionFact.unitName.toLowerCase() !== "g") {
    console.error("Unfamiliar unitName", nutritionFact);
  }
  return nutritionFact.value;
}
