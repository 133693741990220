import React from "react";

interface CircularRangeProps {
  value: number;
  min?: number;
  max?: number;
  radius?: number;
}

const valueAsPercent = (_v: number, min: number, max: number) => {
  return (_v - min) / (max - min);
};

export const FallbackContent: React.FC<CircularRangeProps> = ({
  value = 0,
  min = 0,
  max = 100,
}) => <p>{Math.floor(valueAsPercent(value, min, max) * 100)}%</p>;

export const CircularRange: React.FC<CircularRangeProps> = ({
  value = 0,
  min = 0,
  max = 100,
  radius = 30,
}) => {
  const ref = React.useRef<HTMLCanvasElement>(null);
  const requestId = React.useRef<number | null>(null);
  const animationState = React.useRef<number>(0);
  const initialRender = React.useRef(false);

  const limitArcRange = (_r: number) => Math.min(1.2, Math.max(0.01, _r));

  const percentToColor = (_p: number) => {
    const diff = Math.abs(1.0 - _p);
    if (diff < 0.13) {
      return "#2d8";
    } else if (diff < 0.38) {
      return "#ec4";
    } else {
      return "#e25";
    }
  };

  React.useEffect(() => {
    const render = () => {
      const canvas = ref.current;
      const step = 0.03 * (max - min);

      if (
        canvas &&
        (Math.abs(animationState.current - value) > 0 || !initialRender.current)
      ) {
        initialRender.current = true;
        animationState.current +=
          (animationState.current < value ? 1 : -1) *
          Math.min(step, Math.abs(animationState.current - value));

        const context = canvas.getContext("2d");
        if (context) {
          context.clearRect(0, 0, canvas.width, canvas.height);

          context.strokeStyle = "gray";
          for (let _p = 0.1; _p < 1; _p += 0.1) {
            context.beginPath();
            context.arc(
              radius + 1,
              radius + 1,
              radius,
              0.7 * Math.PI + _p * 1.6 * Math.PI,
              0.7 * Math.PI + _p * 1.6 * Math.PI
            );
            context.arc(
              radius + 1,
              radius + 1,
              radius * 0.75,
              0.7 * Math.PI + _p * 1.6 * Math.PI,
              0.7 * Math.PI + _p * 1.6 * Math.PI
            );
            context.stroke();
          }

          context.strokeStyle = "black";
          context.beginPath();
          context.arc(
            radius + 1,
            radius + 1,
            radius,
            0.7 * Math.PI,
            0.7 * Math.PI +
              limitArcRange(valueAsPercent(animationState.current, min, max)) *
                1.6 *
                Math.PI
          );
          context.arc(
            radius + 1,
            radius + 1,
            radius * 0.75,
            0.7 * Math.PI +
              limitArcRange(valueAsPercent(animationState.current, min, max)) *
                1.6 *
                Math.PI,
            0.7 * Math.PI,
            true
          );

          context.closePath();
          context.stroke();
          context.fillStyle = percentToColor(valueAsPercent(value, min, max));
          context.fill();
        }
      }
      requestId.current = requestAnimationFrame(render);
    };

    render();

    return () => {
      if (requestId.current) {
        cancelAnimationFrame(requestId.current);
      }
    };
  }, [min, max, value, radius]);

  return (
    <canvas ref={ref} width={radius * 2 + 2} height={radius * 2 + 2}>
      <FallbackContent value={value} min={min} max={max} />
    </canvas>
  );
};
