import React from "react";
import "./Fraction.css";

interface FractionInterface {
  numerator: React.ReactElement;
  denominator: React.ReactElement;
}

export const Fraction: React.FC<FractionInterface> = ({
  numerator,
  denominator,
}) => {
  return (
    <div className="Fraction">
      <span className="Fraction--num">{numerator}</span>
      <span className="Fraction--slash">/</span>
      <span className="Fraction--den">{denominator}</span>
    </div>
  );
};
