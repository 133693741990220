import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./utils/utils.css";
import { FileBasedDataProvider } from "./containers/DataProvider";
import { FoodDataSearchProvider } from "./containers/FoodDataSearch";
import { FileHeader } from "./components/views/FileHeader";
import { MainPage } from "./pages/MainPage";

function App() {
  return (
    <Router>
      <FileBasedDataProvider>
        <FoodDataSearchProvider>
          <MainPage header={<FileHeader />} />
        </FoodDataSearchProvider>
      </FileBasedDataProvider>
    </Router>
  );
}

export default App;
