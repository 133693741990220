import React from "react";
import { useNavigate } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { Button } from "./Button";
import { TransitionView } from "./TransitionView";
import "./DetailView.css";

interface DetailViewProps {
  /**
   * Event handler called to close the detail view
   */
  onClose?: () => void;
}

const EmptyView: React.FC<DetailViewProps> = ({ onClose }) => (
  <div className="DetailView__empty">
    <p>Dang, there ain't nothing here.</p>
    <Button size="small" label="Close" className="float-r" onClick={onClose} />
    <div className="clear" />
  </div>
);

/**
 * UI for managing lists of things
 */
export const DetailView: React.FC<DetailViewProps> = ({
  onClose,
  ...props
}) => {
  const navigate = useNavigate();
  const _onClose = onClose || (() => navigate("/"));
  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === "Escape") {
      _onClose();
    }
  };
  const stopBubble: React.MouseEventHandler = (event) => {
    event.stopPropagation();
  };
  return (
    <FocusTrap>
      <WithRef>
        <TransitionView
          name="DetailView"
          onKeyDown={handleKeyDown}
          onMouseDown={_onClose}
        >
          <article onMouseDown={stopBubble}>
            {props.children || <EmptyView onClose={_onClose} />}
          </article>
        </TransitionView>
      </WithRef>
    </FocusTrap>
  );
};

export const useDetailView = () => {
  const [visible, setVisible] = React.useState(false);
  const [id, setId] = React.useState<string | undefined>("");
  return {
    show: (id?: string) => {
      setId(id);
      setVisible(true);
    },
    hide: () => {
      setVisible(false);
    },
    render: (renderFn: (id?: string) => React.ReactNode) =>
      visible && (
        <DetailView onClose={() => setVisible(false)}>
          {renderFn(id)}
        </DetailView>
      ),
  };
};

const WithRef = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => <div ref={ref} {...props} />);
