import React from "react";

const headingStyle: React.CSSProperties = {
  fontSize: "1.2em",
  fontWeight: "600",
  marginTop: "1em",
  marginBottom: "0.2em",
};

const subheadingStyle: React.CSSProperties = {
  fontSize: "1.1em",
  fontWeight: "400",
  marginTop: "0.4em",
  marginBottom: "0.2em",
};

export const Heading: React.FC = (props) => {
  return <div {...props} style={headingStyle} />;
};

export const SubHeading: React.FC<React.ComponentPropsWithoutRef<"div">> = (
  props
) => {
  return <div {...props} style={subheadingStyle} />;
};
