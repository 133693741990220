import React from "react";
import Client from "../utils/fooddata-central";
import { SearchParams } from "../utils/fooddata-central.types";
import { mockSearchResults } from "../utils/fooddata-central.mock-data";

export interface FoodDataSearchInterface {
  search: Client["search"];
  details: Client["details"];
}

export const FoodDataSearchContext =
  React.createContext<FoodDataSearchInterface>({
    search: (t) => ({
      abort: () => {},
      ready: Promise.reject("Not initialized"),
    }),
    details: (id) => ({
      abort: () => {},
      ready: Promise.reject("Not initialized"),
    }),
  });

export const FoodDataSearchProvider: React.FC = (props) => {
  const client = React.useRef(new Client({ api_key: "DEMO_KEY" }));
  return (
    <FoodDataSearchContext.Provider value={client.current}>
      {props.children}
    </FoodDataSearchContext.Provider>
  );
};

export const MockFoodDataSearchProvider: React.FC = (props) => {
  const mockClient = {
    search: mockSearch,
    details: mockDetails,
  };
  return (
    <FoodDataSearchContext.Provider value={mockClient}>
      {props.children}
    </FoodDataSearchContext.Provider>
  );
};

function mockSearch({
  generalSearchInput,
}: SearchParams): ReturnType<Client["search"]> {
  const filtered = generalSearchInput
    ? mockSearchResults.filter((_f) =>
        _f.description.toLowerCase().includes(generalSearchInput.toLowerCase())
      )
    : [];
  return {
    abort: () => {},
    ready: Promise.resolve({
      success: true,
      data: {
        foodSearchCriteria: {
          generalSearchInput: generalSearchInput || "",
          pageNumber: 1,
          requireAllWords: false,
        },
        totalHits: filtered.length,
        currentPage: 1,
        totalPages: 1,
        foods: filtered,
      },
    }),
  };
}

function mockDetails(): ReturnType<Client["details"]> {
  return {
    abort: () => {},
    ready: Promise.resolve({
      success: false,
      error: new Error("YAGNI?"),
    }),
  };
}
