import React from "react";
import { v4 as uuidv4 } from "uuid";
import FileSaver from "file-saver";
import { IRecipe } from "../components/views/Recipe";
import { IProfile } from "../components/views/Profile";
import { IOrder } from "../components/views/Order";
import { IBatch } from "../components/views/Batch";
import { ServingUnit } from "../utils/parse-macros";

export const RecipeContext = React.createContext<{
  recipes: IRecipe[];
  upsertRecipe: (item: IRecipe) => void;
}>({
  recipes: [],
  upsertRecipe: (item) => {},
});

export const ProfileContext = React.createContext<{
  profiles: IProfile[];
  upsertProfile: (item: IProfile) => void;
}>({
  profiles: [],
  upsertProfile: (item) => {},
});

export const OrderContext = React.createContext<{
  orders: IOrder[];
  upsertOrder: (item: IOrder) => void;
}>({
  orders: [],
  upsertOrder: (item) => {},
});

export const BatchContext = React.createContext<{
  batches: IBatch[];
  upsertBatch: (item: IBatch) => void;
}>({
  batches: [],
  upsertBatch: (item) => {},
});

export const FileContext = React.createContext<{
  exportSession: () => void;
  importSession: () => void;
}>({
  exportSession: () => {},
  importSession: () => {},
});

export const FileBasedDataProvider: React.FC = (props) => {
  const {
    items: recipes,
    upsertItem: upsertRecipe,
    setItems: setRecipes,
  } = usePersistedUpsertableListState<IRecipe>("recipe");
  const recipeProps = { recipes, upsertRecipe };

  const {
    items: profiles,
    upsertItem: upsertProfile,
    setItems: setProfiles,
  } = usePersistedUpsertableListState<IProfile>("profile");
  const profileProps = { profiles, upsertProfile };

  const {
    items: orders,
    upsertItem: upsertOrder,
    setItems: setOrders,
  } = usePersistedUpsertableListState<IOrder>("order");
  const orderProps = { orders, upsertOrder };

  const {
    items: batches,
    upsertItem: upsertBatch,
    setItems: setBatches,
  } = usePersistedUpsertableListState<IBatch>("batch");
  const batchProps = { batches, upsertBatch };

  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    inputRef.current?.addEventListener("change", () => {
      const fileList = inputRef.current?.files;
      if (fileList?.length !== 1) {
        console.error("Invalid FileList");
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const datas = parseString(e.target?.result as string);
          setRecipes(datas?.recipes || []);
          setProfiles(datas?.profiles || []);
          setOrders(datas?.orders || []);
          setBatches(datas?.batches || []);
        } catch (e) {
          console.error("Parse error", e);
        }
      };
      reader.readAsText(fileList[0]);
    });
  }, [setRecipes, setProfiles, setOrders, setBatches]);

  const fileProps = {
    exportSession: () => {
      const blob = new Blob(
        [serializeToString(recipes, profiles, orders, batches)],
        { type: "application/json;charset=utf-8" }
      );
      FileSaver.saveAs(blob, "Macrows_data.json");
    },
    importSession: () => {
      inputRef.current?.click();
    },
  };

  return (
    <FileContext.Provider value={fileProps}>
      <input
        type="file"
        ref={inputRef}
        accept="application/json"
        style={{ display: "none" }}
      />
      <RecipeContext.Provider value={recipeProps}>
        <ProfileContext.Provider value={profileProps}>
          <OrderContext.Provider value={orderProps}>
            <BatchContext.Provider value={batchProps}>
              {props.children}
            </BatchContext.Provider>
          </OrderContext.Provider>
        </ProfileContext.Provider>
      </RecipeContext.Provider>
    </FileContext.Provider>
  );
};

function serializeToString(
  recipes: IRecipe[],
  profiles: IProfile[],
  orders: IOrder[],
  batches: IBatch[]
) {
  return JSON.stringify({ recipes, profiles, orders, batches });
}

function parseString(serialized: string) {
  return JSON.parse(serialized);
}

export const mockRecipes = [
  {
    name: "Chicken and Rice",
    id: uuidv4(),
    created: "1644290663",
    ingredients: [
      {
        id: uuidv4(),
        fdcId: 123,
        name: "Chicken",
        nutritionFacts: { fat: 5, protein: 20, carbs: 0 },
        servingSize: 100,
        servingUnit: ServingUnit.Grams,
        autoScale: true,
      },
      {
        id: uuidv4(),
        fdcId: 345,
        name: "Rice",
        nutritionFacts: { fat: 1, protein: 0, carbs: 20 },
        servingSize: 100,
        servingUnit: ServingUnit.Grams,
        autoScale: true,
      },
    ],
  },
  {
    name: "Bean Burrito",
    id: uuidv4(),
    created: "1644090663",
    ingredients: [
      {
        id: uuidv4(),
        name: "Beans",
        nutritionFacts: { fat: 5, protein: 10, carbs: 3 },
        servingSize: 150,
        servingUnit: ServingUnit.Grams,
        autoScale: true,
      },
      {
        id: uuidv4(),
        name: "Tortilla",
        nutritionFacts: { fat: 1, protein: 0, carbs: 5 },
        servingSize: 80,
        servingUnit: ServingUnit.Grams,
        autoScale: false,
      },
    ],
  },
  { name: "Test Meal 2", id: uuidv4(), created: "1644200663", ingredients: [] },
  { name: "Test Meal 3", id: uuidv4(), created: "1644190663", ingredients: [] },
];

export const mockProfiles = [
  {
    name: "John H",
    id: uuidv4(),
    created: "1644290663",
    nutrientGoals: { fat: 15, protein: 40, carbs: 65 },
  },
  {
    name: "Sally A",
    id: uuidv4(),
    created: "1644690663",
    nutrientGoals: { fat: 14, protein: 25, carbs: 35 },
  },
];

export const MockDataProvider: React.FC = (props) => {
  const { items: recipes, upsertItem: upsertRecipe } =
    useUpsertableListState<IRecipe>(mockRecipes);
  const recipeProps = { recipes, upsertRecipe };

  const { items: profiles, upsertItem: upsertProfile } =
    useUpsertableListState<IProfile>(mockProfiles);
  const profileProps = { profiles, upsertProfile };

  const { items: orders, upsertItem: upsertOrder } =
    useUpsertableListState<IOrder>([]);
  const orderProps = { orders, upsertOrder };

  const { items: batches, upsertItem: upsertBatch } =
    useUpsertableListState<IBatch>([]);
  const batchProps = { batches, upsertBatch };

  return (
    <RecipeContext.Provider value={recipeProps}>
      <ProfileContext.Provider value={profileProps}>
        <OrderContext.Provider value={orderProps}>
          <BatchContext.Provider value={batchProps}>
            {props.children}
          </BatchContext.Provider>
        </OrderContext.Provider>
      </ProfileContext.Provider>
    </RecipeContext.Provider>
  );
};

function usePersistedUpsertableListState<T extends { id: string }>(
  key: string
) {
  let initialState = [];
  const _initial = window.localStorage.getItem(key);
  if (_initial) {
    initialState = JSON.parse(_initial);
  }
  const { items, setItems, upsertItem } =
    useUpsertableListState<T>(initialState);
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(items));
  }, [key, items]);
  return { items, setItems, upsertItem };
}

function useUpsertableListState<T extends { id: string }>(
  initialState: Array<T>
) {
  const [items, setItems] = React.useState<T[]>(initialState);
  const upsertItem = (item: T) =>
    setItems((prev) => {
      if (prev.some((_i) => _i.id === item.id)) {
        return prev.map((_i) => (_i.id === item.id ? item : _i));
      }
      return prev.concat(item);
    });

  return { items, setItems, upsertItem };
}
