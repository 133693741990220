import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import { Button } from "../primitives/Button";
import { ToggleButton, ToggleButtonOption } from "../primitives/ToggleButton";
import { Input } from "../primitives/Inputs";
import { Heading, SubHeading } from "../primitives/Heading";
import { PieChart } from "../primitives/PieChart";
import { roundDecimals, gramsToCalories } from "../../utils/utils";

interface ProfileProps {
  profile?: IProfile;
  onCancel: () => void;
  onUpsert: (profile: IProfile) => void;
}

export type IProfile = {
  id: string;
  created: string;
  name: string;
  nutrientGoals: {
    protein: number;
    fat: number;
    carbs: number;
  };
};

export const Profile: React.FC<ProfileProps> = ({
  profile = {
    id: "NEW",
    created: `${Math.round(Date.now() / 1000)}`,
    name: "",
    nutrientGoals: { protein: 0, fat: 0, carbs: 0 },
  },
  onCancel,
  onUpsert,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = useForm<IProfile>({
    defaultValues: {
      ...profile,
      id: profile.id === "NEW" ? uuidv4() : profile.id,
    },
  });
  const [inputUnit, setInputUnit] = React.useState("grams");

  const mapCaloriesToGramsRounded = (_p: IProfile) =>
    inputUnit === "grams"
      ? _p
      : {
          ..._p,
          nutrientGoals: {
            protein: roundDecimals(_p.nutrientGoals.protein / 4),
            fat: roundDecimals(_p.nutrientGoals.fat / 9),
            carbs: roundDecimals(_p.nutrientGoals.carbs / 4),
          },
        };

  const mapGramsToCalories = (_p: IProfile["nutrientGoals"]) =>
    inputUnit === "calories" ? _p : gramsToCalories(_p);

  const calorieVals = mapGramsToCalories({
    protein: watch("nutrientGoals.protein") || 0,
    fat: watch("nutrientGoals.fat") || 0,
    carbs: watch("nutrientGoals.carbs") || 0,
  });

  const sumVal = calorieVals.protein + calorieVals.fat + calorieVals.carbs;
  const percentProtein = sumVal ? calorieVals.protein / sumVal : 0;
  const percentFat = sumVal ? calorieVals.fat / sumVal : 0;
  const percentCarbs = sumVal ? calorieVals.carbs / sumVal : 0;

  return (
    <form
      className="profile-wrapper"
      onSubmit={handleSubmit((_p) => onUpsert(mapCaloriesToGramsRounded(_p)))}
    >
      <h1>{profile.id === "NEW" ? "Add New " : "Edit "}Profile</h1>

      <div>
        <label>
          <Heading>Name</Heading>
          <Input required {...register("name")} />
        </label>
      </div>

      <div className="d-flex flex-wrap mb-1" style={{ alignItems: "center" }}>
        <div style={{ minWidth: 180 }}>
          <Heading>Nutrient Goals</Heading>
          <ToggleButton
            onToggle={setInputUnit}
            selected={inputUnit}
            size="small"
            square
            className="mt-1"
          >
            <ToggleButtonOption label="grams" />
            <ToggleButtonOption label="calories" />
          </ToggleButton>

          <label className="d-block mt-2">
            <Input
              type="number"
              min="0"
              step=".01"
              required
              {...register("nutrientGoals.protein" as const, {
                valueAsNumber: true,
              })}
            />{" "}
            protein
          </label>
          <label className="d-block">
            <Input
              type="number"
              min="0"
              step=".01"
              required
              {...register("nutrientGoals.fat" as const, {
                valueAsNumber: true,
              })}
            />{" "}
            fat
          </label>
          <label className="d-block">
            <Input
              type="number"
              min="0"
              step=".01"
              required
              {...register("nutrientGoals.carbs" as const, {
                valueAsNumber: true,
              })}
            />{" "}
            carbs
          </label>
        </div>

        <div style={{ minWidth: 150, margin: "0 auto" }}>
          <SubHeading>Ratio of Calories</SubHeading>
          <div className="m-2 ml-3">
            <PieChart values={[percentProtein, percentFat, percentCarbs]} />
          </div>
          Total:{" "}
          {Number(
            calorieVals.protein + calorieVals.fat + calorieVals.carbs
          ).toFixed(0)}{" "}
          Calories
        </div>
      </div>

      <div style={{ display: "inline", float: "right" }}>
        {isDirty ? (
          <>
            <Button size="small" onClick={onCancel} label="Cancel" />
            <Button primary size="small" type="submit" label="Save" />
          </>
        ) : (
          <Button size="small" onClick={onCancel} label="Close" />
        )}
      </div>
      <div style={{ clear: "both" }} />
    </form>
  );
};
