import React from "react";
import { FoodDataSearchContext } from "./FoodDataSearch";
import { SearchResults, DetailsResults } from "../utils/fooddata-central.types";

export interface FDSearchStateInterface {
  search: (terms: string) => Promise<SearchResults | undefined>;
  getDetails: (fdcId: number) => Promise<DetailsResults | undefined>;
  abort: () => void;
  searchResults: any[];
  clearResults: () => void;
  isSearching: boolean;
}

/**
 * Search state manager/provider
 */
export const FDSearchStateContext = React.createContext<FDSearchStateInterface>(
  {
    search: (t) => Promise.reject("Not initialized"),
    getDetails: (id) => Promise.reject("Not initialized"),
    abort: () => {},
    searchResults: [],
    clearResults: () => {},
    isSearching: false,
  }
);

export const FDSearchStateProvider: React.FC = (props) => {
  const searchClient = React.useContext(FoodDataSearchContext);

  const [searchResults, setSearchResults] = React.useState<
    SearchResults["foods"]
  >([]);
  const [isSearching, _setSearching] = React.useState(false);
  const abortCb = React.useRef(() => {});

  const search = (terms: string) => {
    if (isSearching) return Promise.resolve(undefined);
    _setSearching(true);
    const request = searchClient.search({
      generalSearchInput: terms,
      pageSize: 15,
    });
    abortCb.current = request.abort;
    return (async () => {
      const results = await request.ready;
      _setSearching(false);
      if (results.success) {
        setSearchResults(results.data!.foods);
      } else {
        setSearchResults([]);
      }
      return results.data;
    })();
  };

  const getDetails = (fdcId: number) => {
    if (isSearching) return Promise.resolve(undefined);
    _setSearching(true);
    const request = searchClient.details(fdcId);
    abortCb.current = request.abort;
    return (async () => {
      const results = await request.ready;
      _setSearching(false);
      if (!results.success) {
        console.error(
          `Failed to get details for fdcId="${fdcId}" (HTTP status: ${results.status})`,
          results.error
        );
        return results.data;
      }
      setSearchResults([]);
      return results.data;
    })();
  };

  return (
    <FDSearchStateContext.Provider
      value={{
        search,
        getDetails,
        abort: abortCb.current,
        searchResults,
        clearResults: () => setSearchResults([]),
        isSearching,
      }}
    >
      {props.children}
    </FDSearchStateContext.Provider>
  );
};

export const MockFDSearchStateProvider: React.FC<{
  value?: Partial<FDSearchStateInterface>;
}> = ({ value = {}, children }) => {
  return (
    <FDSearchStateContext.Provider
      value={{
        search: (terms: string) => Promise.reject("Not initialized"),
        getDetails: (id) => Promise.reject("Not initialized"),
        abort: () => {},
        searchResults: [],
        clearResults: () => {},
        isSearching: false,
        ...value,
      }}
    >
      {children}
    </FDSearchStateContext.Provider>
  );
};
