import React from "react";
import "./Button.css";

interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * How large should the button be?
   */
  size?: "tiny" | "small" | "medium" | "large";
  /**
   * Button contents
   */
  label: string | React.ReactNode;
  square?: boolean;
  /**
   * Override button state
   */
  state?: "active";
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  primary = false,
  size = "medium",
  type = "button",
  square = false,
  state,
  label,
  className = "",
  ...props
}) => {
  const mode = primary ? "Button--primary" : "Button--secondary";
  const squareCx = square ? "Button--square" : "";
  const stateCx = state ? `Button__${state}` : "";
  return (
    <button
      type={type}
      className={[
        "Button",
        `Button--${size}`,
        mode,
        squareCx,
        stateCx,
        className,
      ].join(" ")}
      {...props}
    >
      {label}
    </button>
  );
};

export const CloseButton: React.FC<
  Omit<ButtonProps, "label" | "size" | "style">
> = (props) => {
  return (
    <Button
      label={<CloseIcon />}
      size="small"
      style={{ height: 14, boxSizing: "content-box", padding: 10 }}
      {...props}
    />
  );
};

const CloseIcon: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3L11 11M3 11L11 3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InlineLinkButton: React.FC<
  React.ComponentPropsWithoutRef<"button">
> = ({ type = "button", className = "", ...props }) => (
  <button type={type} {...props} className={`ButtonInline ${className}`} />
);
