import React from "react";
import { Button } from "./Button";
import "./ListManager.css";

interface ListManagerProps {
  /**
   * Name of things being displayed (will be rendered)
   */
  label: string;
  /**
   * Item data to be displayed in the list
   */
  items: Array<{
    name: string;
    id: string;
  }>;
  /**
   * Event handler to add a new list item
   */
  onClickAddItem?: () => void;
  /**
   * Event handler for selecting an item
   */
  onClickSelectItem?: (itemId: string) => void;
}

/**
 * UI for managing lists of things
 */
export const ListManager = ({
  label,
  items = [],
  onClickAddItem,
  onClickSelectItem,
  ...props
}: ListManagerProps) => {
  const selectable = onClickSelectItem ? "listManager__selectable" : false;
  return (
    <div
      className={["listManager--container", selectable]
        .filter(Boolean)
        .join(" ")}
    >
      <ul>
        {items.map((item) => (
          <li
            key={item.id}
            onClick={() => onClickSelectItem && onClickSelectItem(item.id)}
          >
            {item.name}
          </li>
        ))}
        {items.length === 0 && (
          <li className="listManager--empty-item">No {label} exist yet.</li>
        )}
      </ul>
      <Button
        label={`Add ${label}`}
        onClick={onClickAddItem}
        disabled={!onClickAddItem}
      />
    </div>
  );
};
