import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm, useFieldArray } from "react-hook-form";
import { Heading } from "../primitives/Heading";
import { Subitem } from "../primitives/Subitem";
import { Button } from "../primitives/Button";
import { Input, Select } from "../primitives/Inputs";
import { CustomizedMeal } from "./CustomizedMeal";
import { IRecipe } from "./Recipe";
import { IProfile } from "./Profile";
import "./Order.css";

interface OrderProps {
  order?: IOrder;
  recipes: IRecipe[];
  profiles: IProfile[];
  onCancel: () => void;
  onUpsert: (order: IOrder) => void;
}

export interface IOrder {
  id: string;
  created: string;
  name: string;
  meals: ICustomizedMeal[];
}

interface ICustomizedMeal {
  id: string;
  profileId: string;
  recipeId: string;
  targetCals: number;
  quantity: number;
  ingredients: {
    id: string;
    // Assumes the same servingUnit as source Recipe
    amount: number;
  }[];
}

type SubFormValues = {
  profileId: string;
  recipeId: string;
};

export const Order: React.FC<OrderProps> = ({
  order = {
    id: "NEW",
    created: `${Math.round(Date.now() / 1000)}`,
    name: "",
    meals: [],
  } as Partial<IOrder>,
  recipes = [],
  profiles = [],
  onCancel,
  onUpsert,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = useForm<IOrder>({
    defaultValues: {
      ...order,
      id: order.id === "NEW" ? uuidv4() : order.id,
    },
  });
  const {
    fields: customizedMeals,
    append: appendCustomizedMeal,
    remove: removeCustomizedMeal,
  } = useFieldArray<IOrder, "meals", "key">({
    control,
    name: "meals",
    keyName: "key",
  });
  const defaultMeal = recipes.length ? recipes[0].id : undefined;
  const defaultProfile = profiles.length ? profiles[0].id : undefined;
  const { register: registerSubform, watch: watchSubform } =
    useForm<SubFormValues>({
      defaultValues: {
        recipeId: defaultMeal,
        profileId: defaultProfile,
      },
    });
  const recipeId = watchSubform("recipeId", defaultMeal);
  const profileId = watchSubform("profileId", defaultProfile);

  const addMealToOrder = () => {
    appendCustomizedMeal({
      id: uuidv4(),
      recipeId,
      profileId,
      quantity: 1,
      ingredients: [],
    });
  };

  return (
    <form
      className="order-wrapper"
      onSubmit={handleSubmit((_o) => onUpsert(_o))}
    >
      <h1>{order.id === "NEW" ? "Add New " : "Edit "}Order</h1>

      <div>
        <label>
          <Heading>Order name</Heading>
          <Input required {...register("name")} aria-label="Order name" />
        </label>
      </div>

      <Heading>Meals</Heading>
      {customizedMeals.map((field, index) => (
        <Subitem key={field.key}>
          <CustomizedMeal
            register={register}
            watch={watch}
            remove={removeCustomizedMeal}
            fieldIndex={index}
            profile={profiles.find((p) => p.id === field.profileId)}
            recipe={recipes.find((m) => m.id === field.recipeId)}
          />
        </Subitem>
      ))}
      {customizedMeals.length === 0 && (
        <p style={{ color: "gray" }}>No meals have been added yet.</p>
      )}

      <div>
        <Heading>Add meal</Heading>
        <div className="d-flex" style={{ alignItems: "flex-end" }}>
          <label
            className="d-iblock flex-auto"
            style={{ maxWidth: 150, marginRight: 10 }}
          >
            Profile
            <br />
            <Select
              {...registerSubform("profileId")}
              style={{ width: "100%", maxWidth: 150 }}
            >
              {profiles.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
            </Select>
          </label>

          <label className="d-iblock flex-auto" style={{ marginRight: 10 }}>
            Recipe
            <br />
            <Select
              {...registerSubform("recipeId")}
              style={{ width: "100%", maxWidth: 150 }}
            >
              {recipes.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.name}
                </option>
              ))}
            </Select>
          </label>
          <Button
            onClick={addMealToOrder}
            label={
              <>
                Add<span className="Order--add-label-suffix"> to order</span>
              </>
            }
            className="float-r"
          />
        </div>
      </div>

      <div className="float-r mt-3">
        {isDirty ? (
          <>
            <Button size="small" onClick={onCancel} label="Cancel" />
            <Button
              primary
              size="small"
              type="submit"
              disabled={!customizedMeals.length}
              label="Save"
            />
          </>
        ) : (
          <Button size="small" onClick={onCancel} label="Close" />
        )}
      </div>
      <div style={{ clear: "both" }} />
    </form>
  );
};
