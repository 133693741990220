import React from "react";

/**
 * Adds a {name}__pre class on inital render,
 * re-renders (immediately) with __pre class removed.
 *
 * Intended for triggering CSS animations.
 */
export const TransitionView: React.FC<
  {
    name: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ name, ...props }) => {
  const [hasRendered, _setRendered] = React.useState(false);
  React.useEffect(() => {
    if (!hasRendered) {
      _setRendered(true);
    }
  }, [hasRendered]);
  const cx = [name];
  if (!hasRendered) {
    cx.push(`${name}__pre`);
  }
  return <div {...props} className={cx.join(" ")} />;
};
