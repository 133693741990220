import React from "react";
import "./Inputs.css";

/**
 * Form component for gathering user input
 */
export const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
  return <input {...props} ref={ref} />;
});

/**
 * Form component for selecting from a list of options
 */
export const Select = React.forwardRef<
  HTMLSelectElement,
  React.SelectHTMLAttributes<HTMLSelectElement>
>((props, ref) => {
  return <select {...props} ref={ref} />;
});
