import React from "react";
import "./Footer.css";

export const Footer: React.FC = () => {
  return (
    <footer className="Footer mt-4 mb-4">
      <div>Code and Design by Nathan Sollenberger</div>
      <div className="mt-2">
        <a href="/storybook" className="nowrap">
          <StorybookIcon /> View Storybook component library
        </a>
        {` `}
        <a
          href="https://gitlab.com/nsollenberger/macrows"
          rel="noopener noreferer"
          className="ml-2 nowrap"
        >
          <GitIcon /> View source code on GitLab
        </a>
      </div>
    </footer>
  );
};

const GitIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6979 7.28711L8.71226 0.3018C8.31016 -0.100594 7.65768 -0.100594 7.25502 0.3018L5.80457 1.75251L7.64462 3.59255C8.07224 3.44815 8.56264 3.54507 8.90339 3.8859C9.2462 4.22884 9.3423 4.72345 9.19416 5.15263L10.9677 6.92595C11.3969 6.77801 11.8917 6.87364 12.2345 7.21692C12.7133 7.69562 12.7133 8.47162 12.2345 8.95072C11.7553 9.42983 10.9795 9.42983 10.5001 8.95072C10.1399 8.59023 10.0508 8.06101 10.2335 7.61726L8.5793 5.96325V10.3157C8.69594 10.3735 8.80613 10.4505 8.90339 10.5475C9.38223 11.0263 9.38223 11.8022 8.90339 12.2817C8.42456 12.7604 7.64815 12.7604 7.16967 12.2817C6.69083 11.8022 6.69083 11.0263 7.16967 10.5475C7.28802 10.4293 7.42507 10.3399 7.5713 10.28V5.88728C7.42507 5.82742 7.28835 5.73874 7.16967 5.61971C6.80701 5.25717 6.71974 4.72481 6.90575 4.27937L5.09177 2.46512L0.301736 7.25474C-0.100579 7.65747 -0.100579 8.30995 0.301736 8.71233L7.28767 15.6977C7.68985 16.1 8.34213 16.1 8.74491 15.6977L15.6979 8.7447C16.1004 8.34225 16.1004 7.68944 15.6979 7.28711"
      fill="black"
    />
  </svg>
);

const StorybookIcon = () => (
  <svg
    style={{ verticalAlign: "-0.125em" }}
    width="0.81em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 256 319"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M9.872 293.324L.012 30.574C-.315 21.895 6.338 14.54 15.005 14L238.494.032c8.822-.552 16.42 6.153 16.972 14.975c.02.332.031.665.031.998v286.314c0 8.839-7.165 16.004-16.004 16.004c-.24 0-.48-.005-.718-.016l-213.627-9.595c-8.32-.373-14.963-7.065-15.276-15.388z"
        id="ssvg-id-storybook-icona"
      />
    </defs>
    <mask id="ssvg-id-storybook-iconb" fill="#fff">
      <use xlinkHref="#ssvg-id-storybook-icona" />
    </mask>
    <use fill="#FF4785" xlinkHref="#ssvg-id-storybook-icona" />
    <path
      d="M188.665 39.127l1.527-36.716L220.884 0l1.322 37.863a2.387 2.387 0 0 1-3.864 1.96l-11.835-9.325l-14.013 10.63a2.387 2.387 0 0 1-3.829-2.001zm-39.251 80.853c0 6.227 41.942 3.243 47.572-1.131c0-42.402-22.752-64.684-64.415-64.684c-41.662 0-65.005 22.628-65.005 56.57c0 59.117 79.78 60.249 79.78 92.494c0 9.052-4.433 14.426-14.184 14.426c-12.705 0-17.729-6.49-17.138-28.552c0-4.786-48.458-6.278-49.936 0c-3.762 53.466 29.548 68.887 67.665 68.887c36.935 0 65.892-19.687 65.892-55.326c0-63.36-80.961-61.663-80.961-93.06c0-12.728 9.455-14.425 15.07-14.425c5.909 0 16.546 1.042 15.66 24.801z"
      fill="#FFF"
      mask="url(#ssvg-id-storybook-iconb)"
    />
  </svg>
);
