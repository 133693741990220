import React from "react";
import "./Subitem.css";

export const Subitem: React.FC = (props) => {
  return (
    <div className="Subitem">
      <div />
      <div {...props} />
    </div>
  );
};
