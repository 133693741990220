export interface SearchParams {
  generalSearchInput?: string;
  includeDataTypeList?: string | string[];
  ingredients?: string;
  brandOwner?: string;
  requireAllWords?: boolean;
  /**
   * Default is 50
   */
  pageSize?: number;
  pageNumber?: number;
  sortField?:
    | "lowercaseDescription.keyword"
    | "dataType.keyword"
    | "publishedDate"
    | "fdcId";
  sortDirection?: "asc" | "desc";
}

export enum Nutrients {
  Protein = 1003,
  Fat = 1004,
  Carbs = 1005,
}

export interface SearchResults {
  foodSearchCriteria: {
    generalSearchInput: string;
    pageNumber: number;
    requireAllWords: boolean;
  };
  totalHits: number;
  currentPage: number;
  totalPages: number;
  foods: {
    fdcId: number;
    description: string;
    additionalDescriptions?: string;
    scientificName?: string;
    brandName?: string;
    brandOwner?: string;
    dataType: string;
    dataSource?: string;
    foodNutrients: AbbreviatedFoodNutrient[];
    ndbNumber?: string;
    foodCode?: string;
    gtinUpc?: string;
    ingredients?: string;
    servingSize?: number;
    servingSizeUnit?: "g" | "ml";
    publishedDate: string;
    allHighlightFields: string;
    score: number;
  }[];
}

export interface AbbreviatedFoodNutrient {
  nutrientId: number;
  nutrientName: string;
  unitName: "G";
  value: number;
}

export interface ExpandedFoodNutrient {
  type: "FoodNutrient";
  id: number;
  nutrient: {
    id: number;
    number: string;
    name: string;
    rank: number;
    unitName: "g" | "mg" | "IU" | "kcal";
  };
  foodNutrientDerivation?: {};
  nutrientAnalysisDetails?: [
    {
      subSampleId: number;
      amount: number;
      nutrientId: number;
      nutrientAcquisitionDetails: [{}];
      labMethodDescription: string;
      labMethodTechnique: string;
      labMethodOriginalDescription: string;
      labMethodLink: string;
    }
  ];
  amount: number;
}

interface FoodPortion {
  id: number;
  measureUnit: {
    id: number;
    name: string;
    abbreviation: string;
  };
  modifier: string;
  gramWeight: number;
  portionDescription: string;
  sequenceNumber: number;
}

interface InputFood {
  id: number;
  unit: string;
  portionDescription: string;
  portionCode: string;
  srCode: number;
  srDescription: string;
  foodDescription: string;
  gramWeight: number;
  surveyFlag: number;
  amount: number;
  sequenceNumber: number;
}

type FixMe = any;

// TODO: some fields may be undefined
export interface DetailsResults {
  fdcId: number;
  foodClass: string;
  dataType: string;
  description: string;
  publicationDate: string;
  foodNutrients: ExpandedFoodNutrient[];
  foodComponents?: FixMe[];
  foodAttributes?: FixMe[];
  foodPortions?: FoodPortion[];
  inputFoods?: InputFood[];
  tableAliasName?: string;
  foodCode?: string;
  startDate?: string;
  endDate?: string;
  wweiaFoodCategory?: {
    wweiaFoodCategoryCode: number;
    wweiaFoodCategoryDescription: string;
  };
  changes?: string;
  servingSize?: number;
  servingSizeUnit?: "g";
  ingredients?: string;
  brandedFoodCategory?: string;
  brandName?: string;
}
