import React from "react";
import { Button } from "./Button";
import "./ToggleButton.css";

interface ToggleButtonProps
  extends Omit<React.ComponentProps<typeof Button>, "label"> {
  onToggle: (newId: string) => void;
  selected?: string;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  selected: selectedId,
  onToggle,
  children,
  className,
  ...props
}) => {
  const cx = ["ToggleButton--container"];
  if (className) cx.push(className);
  return (
    <div className={cx.join(" ")}>
      <ul className="ToggleButton--options" role="menu">
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child as React.ReactElement, {
            ...props,
            index: index,
            count: React.Children.count(children),
            selectedId,
            _onClick: onToggle,
          })
        )}
      </ul>
    </div>
  );
};

interface ToggleButtonOptionProps extends React.ComponentProps<typeof Button> {
  value?: string;
  index?: number;
  count?: number;
  selectedId?: string;
  _onClick?: (id: string) => void;
}

export const ToggleButtonOption: React.FC<ToggleButtonOptionProps> = ({
  label,
  value,
  index,
  count,
  selectedId,
  _onClick,
  ...props
}) => {
  const styleOverride: React.CSSProperties = {};
  if (count! > 1) {
    if (index! < count! - 1) {
      styleOverride.borderTopRightRadius = 0;
      styleOverride.borderBottomRightRadius = 0;
    }
    if (index! > 0) {
      styleOverride.borderTopLeftRadius = 0;
      styleOverride.borderBottomLeftRadius = 0;
    }
  }
  const _v = value || label?.toString();
  return (
    <li className="ToggleButtonOption">
      <Button
        {...props}
        label={label}
        onClick={() => _onClick && _v && _onClick(_v)}
        style={styleOverride}
        state={_v && _v === selectedId ? "active" : undefined}
      />
    </li>
  );
};
