import React from "react";

interface PieChartProps {
  values: number[];
  radius?: number;
}

const valuesAsPercent = (values: number[]) => {
  const sum = values.reduce(
    (acc, curr) => acc + (Number.isNaN(curr) ? 0 : curr),
    0
  );
  return values.map((_v) => (sum ? _v / sum : 0));
};

export const FallbackContent: React.FC<PieChartProps> = ({ values = [] }) => {
  const percentValues = valuesAsPercent(values);
  return (
    <p>
      {percentValues.map((_v, _i) => (
        <span key={_i}>{Number(_v * 100).toFixed(0)}%, </span>
      ))}
    </p>
  );
};

export const PieChart: React.FC<PieChartProps> = ({
  values = [],
  radius = 30,
}) => {
  const ref = React.useRef<HTMLCanvasElement>(null);
  const requestId = React.useRef<number | null>(null);
  const animationState = React.useRef<number[]>([]);
  const initialRender = React.useRef(false);

  React.useEffect(() => {
    // ex: 0.10, 0.10, 0.70
    const percentValues = valuesAsPercent(values);
    const animationStep = 0.01;

    while (animationState.current.length < percentValues.length) {
      const prev = animationState.current.length
        ? animationState.current[animationState.current.length - 1]
        : 0;
      animationState.current.push(prev);
    }
    if (animationState.current.length > percentValues.length) {
      animationState.current = animationState.current.slice(
        0,
        percentValues.length
      );
    }

    const render = () => {
      const canvas = ref.current;
      if (
        canvas &&
        (percentValues.some(
          (_v, _i) => Math.abs(_v - animationState.current[_i]) > 0
        ) ||
          !initialRender.current) // "empty state"
      ) {
        initialRender.current = true;
        const diffs = animationState.current.map(
          (val, _i) => percentValues[_i] - val
        );
        const countPosOffset = diffs.reduce<number>(
          (acc, curr) => acc + (curr > 0 ? 1 : 0),
          0
        );
        const countNegOffset = diffs.reduce<number>(
          (acc, curr) => acc + (curr < 0 ? 1 : 0),
          0
        );
        // keeps the graph from wiggling weirdly when inputs change
        let posScalingConstant = 1;
        let negScalingConstant = 1;
        if (countPosOffset && countNegOffset) {
          if (countPosOffset > countNegOffset) {
            negScalingConstant = countPosOffset / countNegOffset;
          } else {
            posScalingConstant = countNegOffset / countPosOffset;
          }
        }
        animationState.current = animationState.current.map((_s, _i, arr) => {
          let stepVal = Math.min(
            animationStep,
            Math.abs(_s - percentValues[_i])
          );
          if (_s > percentValues[_i]) {
            return _s - stepVal * negScalingConstant;
          } else {
            return _s + stepVal * posScalingConstant;
          }
        });

        const context = canvas.getContext("2d");
        if (context) {
          context.clearRect(0, 0, canvas.width, canvas.height);

          context.strokeStyle = "black";
          context.beginPath();
          context.arc(radius + 1, radius + 1, radius, 0, 2.0 * Math.PI);
          context.closePath();
          context.fillStyle = "lightgray";
          context.fill();

          const startingOffset = 0.5 * Math.PI;

          // ex: 0.10 0.20 1.00
          const cumulativeValues = animationState.current.reduce<number[]>(
            (acc, curr) => {
              const prev = acc.length ? acc[acc.length - 1] : 0;
              return acc.concat(curr + prev);
            },
            []
          );

          [0].concat(cumulativeValues).forEach((_v, _i, _arr) => {
            if (_i === 0) return;
            context.beginPath();
            context.arc(
              radius + 1,
              radius + 1,
              0,
              startingOffset + 2.0 * Math.PI * _v,
              startingOffset + 2.0 * Math.PI * _arr[_i - 1],
              true
            );
            context.arc(
              radius + 1,
              radius + 1,
              radius,
              startingOffset + 2.0 * Math.PI * _arr[_i - 1],
              startingOffset + 2.0 * Math.PI * _v
            );
            context.closePath();
            context.stroke();

            context.fillStyle = ["#2d8", "#ec4", "#e25"][(_i - 1) % 3];
            context.fill();
          });
        }
      }
      requestId.current = requestAnimationFrame(render);
    };

    render();

    return () => {
      if (requestId.current) {
        cancelAnimationFrame(requestId.current);
      }
    };
  }, [values, radius]);

  return (
    <canvas ref={ref} width={radius * 2 + 2} height={radius * 2 + 2}>
      <FallbackContent values={values} />
    </canvas>
  );
};
